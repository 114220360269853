<!-- product -->
<template>
  <div class="container">
    <div class="product content-block">
      <div class="title">
        <h2>公司产品</h2>
        <div class="underline"></div>
      </div>
      <div class="product-content">
        <el-row :gutter="30">
          <el-col v-for="(item,index) in productItem" :key="index" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <div class="product-item">
              <div class="mask">
                <h2>{{ item.title }}</h2>
                <p>{{ item.content }}</p>
              </div>
              <img class="bg-img" :src="item.img" alt="">
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Product',
  data () {
    return {
      productItem: [
        {
          title: '极光API',
          content: '自研第三代ApI聚合系统，适配度高，灵活高效，可以实现不同场景的快速调用。',
          img:require('../../assets/images/bg/sc4.jpg')
        },
        {
          title: 'CPA管理系统',
          content: '针对推广渠道公司自研的第五代CPA数据管理分发结算系统',
          img:require('../../assets/images/bg/sc1.jpg')
        },
        {
          title: 'CPA数据检测平台',
          content: '联合云智能大数据共同开发的CPA实时数据监控分析平台',
          img:require('../../assets/images/bg/sc3.jpg')
        },
      ],
    }
  }
}
</script>

<style lang='less' scoped>
.bg-img{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.product-item{
  cursor: pointer;
}
@media (max-width: 1200px) {
.container{
  width: 100%;
  .product{
    .product-content{
      width: 100%;
      box-sizing: border-box;
      .el-col{
        margin-bottom: 1.2rem
      }
      .product-item{
        position: relative;
        height: 250px;
        box-sizing: border-box;
        text-align: center;
        transition: 0.5s;
        color: #fff;
        p{
          font-size: 22px;
          opacity: 0.5;
          transition: 0.5s;
        }
        h2{
          opacity: 0.5;
          transition: 0.5s;
        }
        .mask{
          position: absolute;
          width: 100%;
          height: 100%;
          padding: 20px 50px;
          box-sizing: border-box;
          background-color: #00000073;
          left: 0;
          top: 0;
          z-index: 0;
          transition: 0.5s;
        }
      }

      .product-item:hover{
        transform: scale(1.05);
      }
      .product-item:hover p{
        opacity: 1;
      }
      .product-item:hover h2{
        opacity: 1;
      }
    }
  }
  .content-block{
    width: 100%;
    padding:0.3rem 1rem 2rem 1rem;
    box-sizing: border-box;
  }
}

  .title{
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    h2{
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: .3667rem
    }
    .underline{
      width: 4.8333rem;
      height: 2px;
      background-color: #fc4e55;
      margin: 0 auto;
    }
  }
}

@media (min-width: 1201px) {
.container{
width: 100%;
  .product{
    height:400px;
    .product-content{
      max-width: 1200px;
      margin: 0 auto;
      .product-item{
        position: relative;
        height: 300px;
        box-sizing: border-box;
        text-align: center;
        transition: 0.5s;
        color: #fff;
        p{
          font-size: 22px;
          opacity: 0.5;
          transition: 0.5s;
        }
        h2{
          opacity: 0.5;
          transition: 0.5s;
        }
        .mask{
          position: absolute;
          width: 100%;
          height: 100%;
          padding: 20px 50px;
          box-sizing: border-box;
          background-color: #00000073;
          left: 0;
          top: 0;
          z-index: 0;
          transition: 0.5s;
        }
      }

      .product-item:hover{
        transform: scale(1.05);
      }
      .product-item:hover p{
        opacity: 1;
      }
      .product-item:hover h2{
        opacity: 1;
      }
    }
  }

  .content-block{
    width: 100%;
    padding:40px 0;
  }
}

.title{
    margin-bottom: 50px;
    h2{
      text-align: center;
      font-size: 30px;
      margin-top: 0;
      margin-bottom: 10px
    }
    .underline{
      width: 100px;
      height: 3px;
      background-color: #fc4e55;
      margin: 0 auto;
    }
  }

  
}
</style>
