<!--  -->
<template>
    <el-drawer
      :size="drawerWidth"
      :visible.sync="visible"
      :direction="direction"
      :before-close="handleClose"
      wrapperClosable
      >
      <span slot="title">
        <img class="drawer-logo" src="../../assets/logo/logo.png" alt="">
      </span>
      <el-menu
      :default-active="activeIndex"
      active-text-color="#023970"
      class="el-menu-vertical-demo"
      @close="handleClose"
      @select="handleSelect">
      <el-menu-item index="1">
        <i class="el-icon-s-home"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-menu-item index="2">
        <i class="el-icon-cpu"></i>
        <span slot="title">公司产品</span>
      </el-menu-item>
      <el-menu-item index="3">
        <i class="el-icon-paperclip"></i>
        <span slot="title">主营业务</span>
      </el-menu-item>
      <el-menu-item index="4">
        <i class="el-icon-phone"></i>
        <span slot="title">联系我们</span>
      </el-menu-item>
    </el-menu>
    </el-drawer>
</template>

<script>
export default {
  name:'BjgDrawer',
  data () {
    return {
    }
  },

  props: {
    visible: {
      type: Boolean,
      default:false
    },
    direction: {
      type: String,
      default:'rtl'
    }
  },

  computed: {
    drawerWidth() {
      return this.getDrawerWidth()
    },
    activeIndex() {
      return this.getActiveIndex()
    }
  },

  methods: {
    getActiveIndex() {
      return this.$store.state.activeIndex
    },
    getDrawerWidth() {
      return this.$store.state.drawerWidth
    },
    handleClose() {
      this.$store.commit('changeOpenDrawer')
    },
    handleSelect(key, keyPath) {
      console.log('激活回调', key,);
      this.$store.commit('changeActiveIndex', key)
      const path = this.$route.path
      if (key == '1') {
        path == '/' ? '' : this.$router.replace({path:'/'})
      } else if (key == '2') {
        path == '/product' ? '' : this.$router.replace({path:'/product'})
      }
      
      this.$store.commit('changeOpenDrawer')
    }
  }
}
</script>

<style lang='less' scoped>
@theme-color:#023970;
.el-menu-item{
  border-bottom:1px solid #f1f1f1;
}

.drawer-logo{
  width: 5.3333rem;
}

/deep/#el-drawer__title{
  background-color: @theme-color;
  align-items: center;
  color: #72767b;
  display: flex;
  padding: 5px 20px;
  margin-bottom: 0;
}
</style>
