import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
 
// 用来存储数据
const state = {
  activeIndex:'1',
  isPc: true,
  isOpenDrawer: false,
  drawerWidth: '100%',
  swiperHeight:450
}
// 响应组件中的事件
const actions = {
}
// 操作数据
const mutations = {
  changeDevice(state) {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1200) {
      state.isPc = true
      state.isOpenDrawer = false
    } else {
      state.isPc = false
    }

    if (screenWidth > 768) {
      state.drawerWidth = '70%'
      state.swiperHeight = 450
    } else if (screenWidth <= 768) {
      state.drawerWidth = '100%'
      state.swiperHeight = 200
    }
  },
  changeOpenDrawer(state) {
    state.isOpenDrawer = !state.isOpenDrawer
  },

  changeActiveIndex(state, value) {
    console.log('storeIndex',value);
    state.activeIndex = value
  },

  pathReplaceIndex(state, value) {
    switch (value) {
      case "/":
        state.activeIndex = "1"
        break;
      case "/product":
        state.activeIndex = "2"
        break;
    
      default:
        break;
    }

    console.log("转换完成");
    
  }
}
// 用来将state数据进行加工
const getters = {
 
}
// 新建并暴露store
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters
})
 