// router/index.js

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import Home from '../views/home';
import Product from '../views/product'

const routes = [
  { path: '/', component: Home },
  { path: '/product', component: Product }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;