<!-- BjgFooter -->
<template>
  <div class="footer-container">
    <div v-if="isPc" class="footer-main">
      <div class="footer-col">
        <h5>关于北极光</h5>
        <ul>
          <li><a href="#">公司简介</a></li>
          <li><a href="#">公司产品</a></li>
          <li><a href="#">主营业务</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <h5>业务服务</h5>
        <ul>
          <li><a href="#">技术支持</a></li>
          <li><a href="#">CPA管理系统</a></li>
          <li><a href="#">云智能大数据</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <h5>联系我们</h5>
        <ul>
          <li><a href="#">邮箱：hnbjg@outlook.com</a></li>
          <li><a href="#">微信号：king55330</a></li>
          <li><a href="#">地址：湖南省长沙市雨花区复地星光天地5栋18楼</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <!-- <h5>关注我们</h5> -->
        <div class="focusCode">
          <img src="../../assets/images/code.jpg" alt="">
        </div>
        <p class="saoyisao">扫一扫关注我们</p>
      </div>
    </div>

    <div v-if="isPc" class="footer_bottom">
        <p class="banquan">版权所有2024©湖南北极光网络有限公司</p>
        <p class="beian"><a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2024062667号-1</a>
        </p>
    </div>

    <div v-else class="footer-main-phone">
      <p>版权所有2024©湖南北极光网络有限公司</p>
      <p>
        <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2024062667号-1</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },

  computed: {
    isPc() {
     return this.getIsPc()
    }
  },

  methods: {
    getIsPc() {
      return this.$store.state.isPc
    }
  }
}
</script>

<style lang='less' scoped>
@theme-color:#023970;
@media (max-width: 1200px) {
  .footer-container{
    width: 100%;
    .footer-main-phone{
      padding: 10px 10px;
      background-color: @theme-color;
      text-align: center;

      p{
        font-size: 13px;
        font-weight: normal;
        color: #fff;
      }
      a{
        color: #fff;
        text-align: center;
        margin-left: 10px;
      }
    }
  }
}
@media (min-width: 1201px) {
  ul,ol{
    list-style:none;
    padding: 0;
  }

  .footer-container{
    width: 100%;
    background-color: #f6f6f6;
    padding-top: 50px;
    padding-bottom: 35px;
    .footer-main{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .footer-col{
        padding-left:35px;
        padding-right: 70px;
        border-left: 1px solid #ddd;
        h5{
          font-size: 16px;
        }
        li{
          font-size: 12px;
          font-weight: normal;
          color: #333;
          opacity: .68;
          margin-bottom: 8px;
          a:hover{
            color: @theme-color;
          }
        }

        .focusCode{
          width: 120px;
          height: 120px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .saoyisao{
          font-size: 12px;
          text-align: center;
          margin: 0;
          color: #999;
        }
      }
    }
  }

  .footer_bottom{
    line-height: 1.15;
    font-family: "Microsoft YaHei","微软雅黑",SimSun,"宋体",Helvetica,Arial,sans-serif;
    font-size: 12px;
    color: #000;
    box-sizing: border-box;
    margin: 0;
    padding: 10px 0;
    color: #999;
    p{
      text-align: center;
    }
    .banquan{

    }

    .beian{
      margin-left: 20px;

      a{
        color: #999;
      }
    }
  }
  
}

</style>
