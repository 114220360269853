<!-- 导航栏组件 -->
<template>
  <div class="nav-container">
    <div class="nav-content">
      <div class="logo-block">
        <img class="logo-pic" src="../../assets/logo/logo.png" alt="">
      </div>

      <el-menu v-if="isPc" text-color="#fff" :default-active="activeIndex ? activeIndex : '1'" class="el-menu-bjg" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1"><router-link to="/">首页</router-link></el-menu-item>
        <el-menu-item index="2"><router-link to="/product">公司产品</router-link></el-menu-item>
        <el-menu-item index="3">主营业务</el-menu-item>
        <el-menu-item index="4">联系我们</el-menu-item>
      </el-menu>

      <img class="menu-pic" v-if="!isPc" src="../../assets/images/menu.png" alt="" @click="handleClose">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
      return {
        
      };
  },

  computed: {
    isPc() {
     return this.getIsPc()
    },
    
    activeIndex() {
      return this.getActiveIndex()
    }
    
  },
  methods: {
    getActiveIndex() {
      return this.$store.state.activeIndex
    },
    handleClose() {
      this.$store.commit('changeOpenDrawer')
    },
    getIsPc() {
      return this.$store.state.isPc
    },
    onResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth > 1200) {
        this.isPc = true
      } else {
        this.isPc = false
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.$store.commit('changeActiveIndex',key)
    }
  }
}
</script>

<style lang='less' scoped>
@theme-color:#023970;
@nav-size:1.2rem;

@media (max-width: 1200px) {
  .nav-container{
  width: 100%;
  height: 50px;
  background-color: @theme-color;

  .nav-content{
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo-block{
      .logo-pic{
        width: 5.3333rem;
      }
    }
  }

  .el-menu-bjg{
    height: 100%;
    background-color: @theme-color;

    .el-menu-item{
      height: 100%;
      display: flex;
      align-items: center;
      font-size: @nav-size;
    }
  }
  
}
}

@media (min-width: 1201px) {
  .nav-container{
  width: 100%;
  height: 80px;
  background-color: @theme-color;

  .nav-content{
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: @theme-color;
    .logo-block{
      .logo-pic{
        width: 130px;
      }
    }
  }

  .el-menu-bjg{
    height: 100%;
    background-color: @theme-color;

    .el-menu-item{
      height: 100%;
      display: flex;
      align-items: center;
      font-size: @nav-size;
    }
  }
  
}
  .el-menu-item:hover{  

    color: #409eff !important;  
  }  
  .el-menu-item.is-active {  
    color: #409eff !important;  
  }  
}

.menu-pic{
  width: 30px;
}

</style>
