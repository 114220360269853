var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-container"},[(_vm.isPc)?_c('div',{staticClass:"footer-main"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.isPc)?_c('div',{staticClass:"footer_bottom"},[_c('p',{staticClass:"banquan"},[_vm._v("版权所有2024©湖南北极光网络有限公司")]),_vm._m(4)]):_c('div',{staticClass:"footer-main-phone"},[_c('p',[_vm._v("版权所有2024©湖南北极光网络有限公司")]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-col"},[_c('h5',[_vm._v("关于北极光")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("公司简介")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("公司产品")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("主营业务")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-col"},[_c('h5',[_vm._v("业务服务")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("技术支持")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("CPA管理系统")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("云智能大数据")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-col"},[_c('h5',[_vm._v("联系我们")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("邮箱：hnbjg@outlook.com")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("微信号：king55330")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("地址：湖南省长沙市雨花区复地星光天地5栋18楼")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-col"},[_c('div',{staticClass:"focusCode"},[_c('img',{attrs:{"src":require("../../assets/images/code.jpg"),"alt":""}})]),_c('p',{staticClass:"saoyisao"},[_vm._v("扫一扫关注我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"beian"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("湘ICP备2024062667号-1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("湘ICP备2024062667号-1")])])
}]

export { render, staticRenderFns }