<template>
  <div id="app">
    <BjgNavBar style="flex-shrink: 0;"></BjgNavBar>
    <router-view style="flex:1"></router-view>
    <BjgFooter></BjgFooter>
    <BjgDrawer :visible="isOpenDrawer"></BjgDrawer>
  </div>
</template>

<script>
import BjgNavBar from '@/components/BjgNavBar'
import BjgFooter from '@/components/BjgFooter'
import BjgDrawer from '@/components/BjgDrawer'
export default {
  name: 'App',
  components: {
    BjgNavBar,
    BjgFooter,
    BjgDrawer
  },

  computed: {
    isOpenDrawer() {
      return this.getIsOpen()
    }
  },

  methods: {
    getIsOpen() {
      return this.$store.state.isOpenDrawer
    },
    changeNavIndex() {
      const path = this.$route.path
      this.$store.commit('pathReplaceIndex',path)
    }
  },

  mounted() {
    window.addEventListener('resize', () => {
      this.$store.commit('changeDevice')
    });
    window.addEventListener('load', () => {
      this.$store.commit('changeDevice')
      this.changeNavIndex()
    });
  }
}
</script>

<style lang="less">
@theme-color:#023970;
html,
body{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

a{
  color: #333;
  outline: none;
  text-decoration: none;
}
</style>
