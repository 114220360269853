<!-- 首页 -->
<template>
  <div class="container">
    <BjgSwiper :swiperData="swiperData"></BjgSwiper>

    <div class="product content-block">
      <div class="title">
        <h2>产品介绍</h2>
        <div class="underline"></div>
      </div>
      <div class="product-content">
        <el-row :gutter="30">
          <el-col v-for="(item,index) in productItem" :key="index" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <div class="product-item">
              <div class="mask">
                <h2>{{ item.title }}</h2>
                <p>{{ item.content }}</p>
              </div>
              <img class="bg-img" :src="item.img" alt="">
            </div>
          </el-col>
        </el-row>
        
        
      </div>
    </div>

    <div class="business content-block">
      <div class="title">
        <h2>业务说明</h2>
        <div class="underline"></div>
      </div>
      <div class="business-content">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div :data-type="1" @mouseenter="onMouseEnter($event,1)" @mouseleave="onMouseLeave($event,1)" style="background-size: cover;" class="business-item" :style="{ backgroundImage:`url(${businessItem[0].img})` }">
              <h2>CPA拉新</h2>
              <transition>
                <div v-if="isShow" class="business-description"><p>是指通过注册或下载等具体行为付费的广告推广方式。通过提供佣金或奖励，吸引用户注册、下载APP等目标行为。一些热门的CPA拉新项目包括迅雷网盘拉新、抖音极速版拉新、快手极速版拉新等。</p></div>
              </transition>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div :data-type="2" @mouseenter="onMouseEnter($event,2)" @mouseleave="onMouseLeave($event,2)" class="business-item" :style="{ backgroundImage: `url(${businessItem[1].img})` } ">
              <h2>CPA拉活</h2>
              <transition>
                <div v-if="isShow2" class="business-description"><p>是指通过CPA计费‌的推广方式，‌来激活或重新激活不活跃用户的行为。‌在CPA推广中，‌拉活通常指的是通过一系列营销策略和手段，‌吸引已经注册但不再活跃的用户重新使用或参与应用、‌游戏、‌小程序等</p></div>
              </transition>
            </div>
          </el-col>
        </el-row>
        
        
      </div>
    </div>

    <div class="cooperate content-block">
      <div class="title">
        <h2>合作伙伴</h2>
        <div class="underline"></div>
      </div>
      <div class="cooperate-content">
        <el-row :gutter="20" type="flex" justify="center" style="flex-wrap: wrap;width: 100%;">
          <el-col :span="4.8"><div class="cooperate-item"><img src="../../assets/images/brandLogo/OIP-C.png" alt=""></div></el-col>
          <el-col :span="4.8"><div class="cooperate-item"><img src="../../assets/images/brandLogo/7-200913160953c5.png" alt=""></div></el-col>
          <el-col :span="4.8"><div class="cooperate-item"><img src="../../assets/images/brandLogo/8189567_103220491128_2.png" alt=""></div></el-col>
          <el-col :span="4.8"><div class="cooperate-item"><img src="../../assets/images/brandLogo/R-C (2).png" alt=""></div></el-col>
          <el-col :span="4.8"><div class="cooperate-item"><img src="../../assets/images/brandLogo/icon-1024.png" alt=""></div></el-col>
          <el-col :span="4.8"><div class="cooperate-item"><img src="../../assets/images/brandLogo/R-C (3).png" alt=""></div></el-col>
          <el-col :span="4.8"><div class="cooperate-item"><img src="../../assets/images/brandLogo/R-C (4).png" alt=""></div></el-col>
          <el-col :span="4.8"><div class="cooperate-item"><img src="../../assets/images/brandLogo/R-C.png" alt=""></div></el-col>
          <el-col :span="4.8"><div class="cooperate-item"><img src="../../assets/images/brandLogo/R-C (1).png" alt=""></div></el-col>
          <el-col :span="4.8"><div class="cooperate-item"><img src="../../assets/images/brandLogo/20140211123235-184515148.png" alt=""></div></el-col>
        </el-row>
      </div>
    </div>

    <div class="company content-block js-scroll">
      <div class="title">
        <h2>公司简介</h2>
        <div class="underline"></div>
      </div>
      <div class="company-content">
        <el-row :gutter="20" type="flex" align="middle" justify="center" style="flex-wrap: wrap;">
          <el-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="company-content-txt">
              <div class="jianjie">
                <p>湖南北极光网络有限公司 成立于2023年9月，公司注册资本200万元。我司专注于互联网产品拉新拉活推广，在互联网产品推广(CPA)、促活(CPS)、方面有非常丰富的实战经验。我司有门店、搜索，商店，各类DSP自然流量，支持CPA//CPC/CPD/CPM等多种付费方式。欢迎社会各界合作共赢！</p>
              </div>
              <div class="el-grid">
                <el-row type="flex" style="flex-wrap: wrap;">
                  <el-col :span="12">
                    <p class="num">200+</p>
                    <p>优质媒体数量</p>
                  </el-col>
                  <el-col :span="12">
                    <p class="num">31+</p>
                    <p>投放资源覆盖地区</p>
                  </el-col>
                  <el-col :span="12">
                    <p class="num">20000+</p>
                    <p>日均CPA推广量</p>
                  </el-col>
                  <el-col :span="12">
                    <p class="num">50000+</p>
                    <p>日均CPS激活量</p>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="company-map">
              <img class="map-pic" src="../../assets/images/map/map.png" alt="">
              <el-popover
                v-if="isPc"
                placement="top-start"
                title="详情"
                width="200"
                trigger="hover"
                content="湖南北极光网络有限公司">
                <img class="zuobiao" src="../../assets/images/map/zuobiao.png" alt="" slot="reference">     
              </el-popover>      
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-backtop :bottom="100"></el-backtop>
  </div>
</template>

<script>
import BjgSwiper from '@/components/BjgSwiper'
export default {
  name: 'Home',
  components: {
    BjgSwiper
  },
  data () {
    return {
      isShow: false,
      isShow2: false,
      productItem: [
        {
          title: '极光API',
          content: '自研第三代ApI聚合系统，适配度高，灵活高效，可以实现不同场景的快速调用。',
          img:require('../../assets/images/bg/sc4.jpg')
        },
        {
          title: 'CPA管理系统',
          content: '针对推广渠道公司自研的第五代CPA数据管理分发结算系统',
          img:require('../../assets/images/bg/sc1.jpg')
        },
        {
          title: 'CPA数据检测平台',
          content: '联合云智能大数据共同开发的CPA实时数据监控分析平台',
          img:require('../../assets/images/bg/sc3.jpg')
        },
      ],

      businessItem: [
      {
          title: 'CPA拉新',
          content: '是指通过注册或下载等具体行为付费的广告推广方式。通过提供佣金或奖励，吸引用户注册、下载APP等目标行为。一些热门的CPA拉新项目包括迅雷网盘拉新、抖音极速版拉新、快手极速版拉新等',
          img:require('../../assets/images/bg/sc6.png')
        },
        {
          title: 'CPA拉活',
          content: '是指通过CPA计费‌的推广方式，‌来激活或重新激活不活跃用户的行为。‌在CPA推广中，‌拉活通常指的是通过一系列营销策略和手段，‌吸引已经注册但不再活跃的用户重新使用或参与应用、‌游戏、‌小程序等',
          img:require('../../assets/images/bg/sc5.png')
        },
      ],
      swiperData: [
        {
          img: 'https://ts1.cn.mm.bing.net/th/id/R-C.8aff2b9d88d5d139383e60b167f8a991?rik=KoY0zLpueaB3pA&riu=http%3a%2f%2fseopic.699pic.com%2fphoto%2f50046%2f7545.jpg_wh1200.jpg&ehk=hwU7IXSZasP4RRtavwa%2fEzPiZ25GhoPOusnLtrM4uws%3d&risl=&pid=ImgRaw&r=0',
          url: '/'
        },
        {
          img: 'https://ts1.cn.mm.bing.net/th/id/R-C.4529e5f9b0ebe0c7f6dd0b2f53d42988?rik=1kqUwDeDSKfhBQ&riu=http%3a%2f%2fseopic.699pic.com%2fphoto%2f40048%2f6416.jpg_wh1200.jpg&ehk=plp7goZnfAaTON%2fuFrMv696xXI3apOh%2baJdVXHkSKCs%3d&risl=&pid=ImgRaw&r=0',
          url: '/'
        },
        {
          img: 'https://ts1.cn.mm.bing.net/th/id/R-C.6e45eccf6c065f3f24cb1018702b1968?rik=dIVgPOAlb1DjwA&riu=http%3a%2f%2fseopic.699pic.com%2fphoto%2f40027%2f7940.jpg_wh1200.jpg&ehk=%2bvCPwhyYCpIYWUfBmggsHYfw5ZwSKTQLxAYVhPOkG18%3d&risl=&pid=ImgRaw&r=0',
          url: '/'
        },
        {
          img: 'https://img95.699pic.com/photo/40188/9478.jpg_wh860.jpg',
          url: '/'
        },
      ]
    }
  },

  mounted() {
    this.initScroll();
  },
  beforeDestroy() {
    console.log('摧毁');
    this.removeScrollListener();
  },

  computed: {
    swiperHeight() {
      return this.getSwiperHeight()
    },
    isPc() {
     return this.getIsPc()
    },
  },

  methods: {
    onMouseEnter(e,data) {
      data == 1 ? this.isShow = true : this.isShow2 = true
    },
    onMouseLeave(e,data) {
      data == 2 ? this.isShow2 = false : this.isShow = false
    },
    getSwiperHeight() {
      return this.$store.state.swiperHeight
    },
    getIsPc() {
      return this.$store.state.isPc
    },
    initScroll() {
      let scrollElement = document.getElementsByClassName('js-scroll')[0];
      this.handleScrollEvent(scrollElement);
    },
    removeScrollListener() {
      document.removeEventListener('scroll', this.handleScroll, true);
    },
    handleScrollEvent(scrollElement) {
      this.handleScroll = () => {
        const isFill = scrollElement.getBoundingClientRect().top <= (window.innerHeight || document.documentElement.clientHeight);
        isFill ? scrollElement.classList.add('js-scroll-style') : '';
      };
      document.addEventListener('scroll', this.handleScroll, true);
    },
  }

  
}
</script>

<style lang='less' scoped>
@theme-color:#023970;
.bg-img{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.swiper-item{
  width: 100%;
  height: 100%;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.product-item{
  cursor: pointer;
}
.business-item{
  cursor: pointer;
}
.cooperate-item{
  cursor: pointer;
}
@media (max-width: 1200px) {
.container{
  width: 100%;
  .product{
    .product-content{
      width: 100%;
      box-sizing: border-box;
      .el-col{
        margin-bottom: 1.2rem
      }
      .product-item{
        position: relative;
        height: 250px;
        box-sizing: border-box;
        text-align: center;
        transition: 0.5s;
        color: #fff;
        p{
          font-size: 22px;
          opacity: 0.5;
          transition: 0.5s;
        }
        h2{
          opacity: 0.5;
          transition: 0.5s;
        }
        .mask{
          position: absolute;
          width: 100%;
          height: 100%;
          padding: 20px 50px;
          box-sizing: border-box;
          background-color: #00000073;
          left: 0;
          top: 0;
          z-index: 0;
          transition: 0.5s;
        }
      }

      .product-item:hover{
        transform: scale(1.05);
      }
      .product-item:hover p{
        opacity: 1;
      }
      .product-item:hover h2{
        opacity: 1;
      }
    }
  }

  .business{
    background-color: #0b64be;
    .title{
      color: #fff;
    }
    .business-content{
      margin: 0 auto;
      color: #fff;

      .el-col{
        margin-bottom: 1.2rem
      }
      .business-item{
        position: relative;
        width: 100%;
        height: 250px;
        padding: 20px 50px;
        background-size: contain;
        box-sizing: border-box;
        text-align: center;
        transition: 0.5s;
        overflow: hidden;
        h2{
          position: absolute;
          top: 90px;
          left: 50%;
          transform:translateX(-50%);
          transition: 0.5s;

        }
        p{
          font-size: 1rem;
        }
        .mask{
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #00000035;
          left: 0;
          top: 0;
        }

        .business-description{
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height:150px;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
      .business-item:hover h2{
        top: 20px;
        transform: translateY(0) translateX(-50%);
      }
    }
  }

  .cooperate{
    background-color: #f6f6f6;
    .cooperate-content{
      margin: 0 auto;
    }
    .cooperate-item{
      position: relative;
      width: 3rem;
      padding-top: 100%;
      overflow: hidden;
      border-radius: 100%;
      transition: 0.3s;
      background-color: #fff;

      img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

    }
    .cooperate-item:hover{
      transform: scale(1.05);
    }

    .el-col{
      margin-bottom: 20px
    }
  }

  .company{
    .company-content{
      margin: 0 auto;
      .company-content-txt{
        width: 100%;

        .jianjie{
          p{
            margin-top: 0;
            text-align: justify;
          }
        }
        .el-grid{
          width: 100%;
          .el-col{
            text-align: center;
            border: 1px solid #eee;
            font-size: 16px;
            padding: 5px 0;

            .num{
              padding-top: 20px;
              font-size: 36px;
              color: @theme-color;
              font-weight: bold;
            }
          }
        }
      }

      .company-map{
        position: relative;
        width: 100%;
        .map-pic{
          width: 100%;
          margin-top: 10px;
        }
        .zuobiao{
          position: absolute;
          width: 1.3667rem;
          top: 7.422rem;
          left: 6.9rem;
        }
      }
    }

  }

  .content-block{
    width: 100%;
    padding:0.3rem 1rem 2rem 1rem;
    box-sizing: border-box;
  }
}


.title{
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  h2{
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: .3667rem
  }
  .underline{
    width: 4.8333rem;
    height: 2px;
    background-color: #fc4e55;
    margin: 0 auto;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 450px;
  margin: 0;
  text-align: center
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
}

@media (min-width: 1201px) {
.container{
width: 100%;
  .product{
    height:400px;
    .product-content{
      max-width: 1200px;
      margin: 0 auto;
      .product-item{
        position: relative;
        height: 300px;
        box-sizing: border-box;
        text-align: center;
        transition: 0.5s;
        color: #fff;
        p{
          font-size: 22px;
          opacity: 0.5;
          transition: 0.5s;
        }
        h2{
          opacity: 0.5;
          transition: 0.5s;
        }
        .mask{
          position: absolute;
          width: 100%;
          height: 100%;
          padding: 20px 50px;
          box-sizing: border-box;
          background-color: #00000073;
          left: 0;
          top: 0;
          z-index: 0;
          transition: 0.5s;
        }
      }

      .product-item:hover{
        transform: scale(1.05);
      }
      .product-item:hover p{
        opacity: 1;
      }
      .product-item:hover h2{
        opacity: 1;
      }
    }
  }

  .business{
    background-color: #0b64be;
    .title{
      color: #fff;
    }
    .business-content{
      max-width: 1200px;
      margin: 0 auto;
      color: #fff;
      .business-item{
        position: relative;
        width: 100%;
        height: 300px;
        padding: 20px 50px;
        background-size: contain;
        box-sizing: border-box;
        text-align: center;
        transition: 0.5s;
        overflow: hidden;
        h2{
          position: absolute;
          top: 100px;
          left: 50%;
          transform:translateX(-50%);
          transition: 0.5s;
          font-size: 30px;
        }
        p{
          font-size: 22px;
        }
        .mask{
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #00000035;
          left: 0;
          top: 0;
        }

        .business-description{
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height:150px;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }

      .business-item:hover h2{
        top: 20px;
        transform: translateY(0) translateX(-50%);
      }
    }
  }

  .cooperate{
    background-color: #f6f6f6;
    .cooperate-content{
      max-width: 1200px;
      margin: 0 auto;
    }
    .cooperate-item{
      position: relative;
      width: 220px;
      height: 220px;
      overflow: hidden;
      background-color: #fff;
      border-radius: 100%;
      transition: 0.3s;
      
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
    .cooperate-item:hover{
      transform: scale(1.05);
    }

    .el-col{
      margin-bottom: 20px
    }
  }

  .company{
    .company-content{
      max-width: 1200px;
      margin: 0 auto;
      .company-content-txt{
        width: 100%;
        .jianjie{
          p{
            margin-top: 0;
            text-align: justify;
          }
        }
        .el-grid{
          width: 100%;
          .el-col{
            text-align: center;
            border: 1px solid #eee;
            font-size: 16px;
            padding: 5px 0;

            .num{
              padding-top: 20px;
              font-size: 36px;
              color: @theme-color;
              font-weight: bold;
            }
          }
        }
      }
      .company-map{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        .map-pic{
          width: 100%;
        }
        .zuobiao{
          width: 40px;
          position: absolute;
          top: 182px;
          left: 189px;
          transition: 0.2s;
        }
        .zuobiao:hover{
          transform: scale(1.15);
        }
      }
    }
  }

  .content-block{
    width: 100%;
    padding:40px 0;
  }
}

.title{
  margin-bottom: 50px;
  h2{
    text-align: center;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 10px
  }
  .underline{
    width: 100px;
    height: 3px;
    background-color: #fc4e55;
    margin: 0 auto;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 450px;
  margin: 0;
  text-align: center
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
  
}


.v-enter-active {
  animation: demo1 0.4s linear;
  animation-fill-mode : forwards
}
 
.v-leave-active {
  animation: demo1 0.4s linear reverse;
}
 
@keyframes demo1 {
  from {
    transform: translateY(100%);
    
  }
  to {
    transform: translateY(0);
  }
}

@keyframes scroll-animation{
  from{
    opacity: 0;
    transform: translateY(200px);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
}

.js-scroll-style{
  animation: scroll-animation 1s ease-out;
}

</style>
