<!-- swiper -->
<template>
    <div class="swiper-container" :style="`height:${swiperHeight}px`">
      <div class="swiper-wrapper">
          <div class="swiper-slide"  v-for="item in swiperData" :key="item.img"><a class="swiper-item" :href="item.url"><img :src="item.img" alt=""></a></div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
      
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css'
export default {
  data () {
    return {
    }
  },

  props: {
    swiperData: {
      type: Array,
      default: [
        {
          img: 'https://ts1.cn.mm.bing.net/th/id/R-C.8aff2b9d88d5d139383e60b167f8a991?rik=KoY0zLpueaB3pA&riu=http%3a%2f%2fseopic.699pic.com%2fphoto%2f50046%2f7545.jpg_wh1200.jpg&ehk=hwU7IXSZasP4RRtavwa%2fEzPiZ25GhoPOusnLtrM4uws%3d&risl=&pid=ImgRaw&r=0',
          url: '/'
        },
        {
          img: 'https://ts1.cn.mm.bing.net/th/id/R-C.4529e5f9b0ebe0c7f6dd0b2f53d42988?rik=1kqUwDeDSKfhBQ&riu=http%3a%2f%2fseopic.699pic.com%2fphoto%2f40048%2f6416.jpg_wh1200.jpg&ehk=plp7goZnfAaTON%2fuFrMv696xXI3apOh%2baJdVXHkSKCs%3d&risl=&pid=ImgRaw&r=0',
          url: '/'
        },
        {
          img: 'https://ts1.cn.mm.bing.net/th/id/R-C.6e45eccf6c065f3f24cb1018702b1968?rik=dIVgPOAlb1DjwA&riu=http%3a%2f%2fseopic.699pic.com%2fphoto%2f40027%2f7940.jpg_wh1200.jpg&ehk=%2bvCPwhyYCpIYWUfBmggsHYfw5ZwSKTQLxAYVhPOkG18%3d&risl=&pid=ImgRaw&r=0',
          url: '/'
        },
        {
          img: 'https://img95.699pic.com/photo/40188/9478.jpg_wh860.jpg',
          url: '/'
        },
      ]
    }
  },
  mounted() {
    new Swiper ('.swiper-container', {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  },

  methods: {
    getSwiperHeight() {
      return this.$store.state.swiperHeight
    },
  },

  computed: {
    swiperHeight() {
      return this.getSwiperHeight()
    }
  },
}
</script>

<style lang='less' scoped>
.swiper-container{
  width: 100%;
  --swiper-pagination-color: #ffffff;
  .swiper-button-prev,.swiper-button-next{
    opacity: 0.4;
  }
}

.swiper-item{
  width: 100%;
  height: 100%;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
